body {
  margin: 0px;
}

.Header {
  background: #c7c8c1;
}
.Title {
  padding: 50px;
  font-size: 30px;
}
.InvoicesGridView {
  margin: 50px;
}
