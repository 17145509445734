$primary: #3a6976;
$secondary: #ebf0f1;
$lightSecondary: #f5f7f8;
$black: #0c0c0c;
$lightBlack: #53565a;
$lightInteractionBlack: #141414;
$message: #32c95c;
$white: #ffffff;
$grey: #e4e6eb;
$backgroundBaseLight: #f7f7f7;
$borderSubtleLight: #e1dfdd;
$inputBorderLightGrey: #a7a8a9;
$backgroundImageAccent: #dae3f4;
$red: #bf2012;
$headerLightGray: #ebf0f1;
$warning: #ffa000;
