/* https://www.telerik.com/kendo-react-ui/components/styling/theme-material/customization/ */

// TYPOGRAPHY
.k-h1 {
  font-size: 2.5rem !important;
}
.k-h2 {
  font-size: 2rem !important;
}

.k-label {
  font-family: 'Volvo Novum';
}

// COMMON
$kendo-color-primary: $primary;
$kendo-color-secondary: $secondary;
$kendo-color-success: $message;
$kendo-font-family-sans-serif: 'Volvo Novum';
$kendo-font-family: 'Volvo Novum';

// INPUT
$kendo-input-text: $lightInteractionBlack;
$kendo-input-hover-bg: $backgroundBaseLight;

// BUTTON
$kendo-button-border-radius: 6.25rem;
$kendo-button-theme-colors: (
  'primary': $primary,
  'secondary': $secondary,
  'tertiary': $grey,
  'info': #0058e9,
  'success': #37b400,
  'warning': #ffc000,
  'error': #f31700,
  'dark': $lightInteractionBlack,
  'light': $white,
  'inverse': #424242,
  'base': $white
);
$kendo-button-shadow: none;
.k-button-solid-primary {
  background-color: $primary !important;
  color: $secondary !important;
  &:hover {
    cursor: pointer;
    opacity: 0.9 !important;
  }
  &:disabled {
    opacity: 0.6 !important;
  }
}

.k-button,
.k-link {
  text-transform: none !important;
}

th .k-link {
  justify-content: center !important;
}

.k-button-text {
  font-weight: 400;
}

.k-button-link:hover {
  text-decoration: none !important;
  background: $backgroundBaseLight;
}

.k-button-link:focus {
  background: $lightInteractionBlack;
  color: $white;
  &:hover {
    color: $white;
  }
}
// TABSTRIP
$kendo-tabstrip-item-gap: 1px;
$kendo-tabstrip-item-bg: $grey;
$kendo-tabstrip-item-text: #3c3c3c;
$kendo-tabstrip-item-hover-bg: $white;
$kendo-tabstrip-item-selected-bg: $white;
$kendo-tabstrip-item-selected-text: #396976;
$kendo-tabstrip-item-border-radius: 1.25rem;
$kendo-grid-border: transparent;
$kendo-tabstrip-content-padding-x: 0;
$kendo-tabstrip-content-padding-y: 0;

.k-tabstrip {
  background: transparent;
  .k-tabstrip-items {
    .k-item {
      &::after {
        border-color: transparent !important;
      }
      span {
        font-weight: 700;
        line-height: 20px;
      }
    }
  }
}

// DATA GRID
$kendo-table-border: transparent !important;
$kendo-table-hover-bg: $secondary;
$kendo-grid-selected-bg: $secondary !important;
$kendo-grid-selection-aggregates-bg: $lightSecondary;

.k-grid {
  .k-table-thead {
    background: $secondary;
    font-size: 1.25rem;
    font-weight: 500;
    color: $black;
  }

  .k-column-title {
    font-size: 1rem;
  }
  .k-grid-header-menu {
    padding: 0;
    font-size: 1rem;
    svg {
      font-size: 1rem;
    }
  }
  .k-grid-header-menu {
    .k-svg-i-filter:before {
      content: '\e5d4';
      font-family: 'Material Icons';
    }
  }
}

.k-detail-row {
  background: $lightSecondary !important;
  &:hover {
    background: darken($lightSecondary, 2%) !important;
  }
}

.k-detail-cell {
  padding: 3rem 4rem 2rem 4rem !important;
}

.k-column-resizer {
  border-right: 1px solid rgba($primary, 0.24);
}

.k-grid-header {
  background: $headerLightGray !important;
}

.k-animation-container {
  z-index: 2000 !important;
  font-family: $kendo-font-family !important;
}

.k-pager-sizes {
  .k-dropdownlist {
    width: 6rem !important;
  }
}
// MENU
$kendo-menu-popup-item-bg: $secondary;
$kendo-menu-popup-item-text: #0e0e0e;
$kendo-menu-popup-bg: $secondary;
$kendo-menu-popup-text: #0e0e0e;
$kendo-list-item-hover-bg: lighten($primary, 10%);
$kendo-list-item-selected-bg: $primary;
$kendo-list-item-selected-text: $white;
$kendo-list-item-hover-text: $white;
$kendo-menu-popup-item-hover-text: $white;
$kendo-menu-popup-item-expanded-bg: $primary;
$kendo-menu-popup-item-expanded-text: $white;

// OTHER
.k-master-row:hover {
  cursor: pointer !important;
}

.k-grid-column-menu {
  position: inherit !important;
}

.k-master-row:nth-child(2n of .k-master-row) {
  background: $lightSecondary !important;
  &:hover {
    background: $secondary !important;
  }
}
