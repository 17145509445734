@import './colors.scss';
@import './layouts.scss';
@import 'https://fonts.googleapis.com/icon?family=Material+Icons';
@import './material-customizations.scss';
@import '@progress/kendo-theme-material/dist/all.scss';

$topBarHeight: 7rem;
$footerHeight: 4rem;
$pageContentPaddingBottom: calc($footerHeight + 1rem);
$pageContentHeight: calc(100vh - ($topBarHeight + $footerHeight));
$pageContentNoResultsHeight: calc(100vh - $topBarHeight);
$pagePadding: 6rem;
$pagePaddingSmall: 3rem;

.app {
  height: 100vh;
  background-image: url('../images/bg.webp');
  background-size: cover;
  background-repeat: no-repeat;

  .gearMessageBar {
    position: absolute;
    z-index: 9999;
    .collapseIcon {
      font-size: 0.625rem;
      margin: 0 0.25rem 0 1rem;
      &:hover {
        cursor: pointer;
      }
    }
  }

  .gearPage {
    padding: 0 $pagePadding;
    @media only screen and (max-width: 1600px) {
      padding: 0 $pagePaddingSmall;
    }

    &--accessDenied {
      padding-top: 30px;
    }
  }

  .topbar {
    background-color: transparent;
    box-shadow: none;
    height: $topBarHeight;

    .gearLogo {
      cursor: pointer;
    }

    .globalSearchFilter {
      width: 600px;
      &--reset {
        &:hover {
          cursor: pointer;
          background: $backgroundImageAccent;
          opacity: 0.8;
          color: $primary;
        }
      }
      &--search {
        border-radius: 0.5rem;
        &:hover {
          cursor: pointer;
          color: $white;
          background: $primary;
        }
      }
    }
  }

  .settingsPage {
    .settingsTitle {
      margin: 0 auto;
    }

    .settingsBackHome {
      cursor: pointer;
      width: 2.5rem;
    }

    .removePrivateViewFieldIcon {
      cursor: pointer;
      width: 1.25rem;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .navigation-container {
      .dashboard-button {
        text-decoration: none;

        button {
          color: $black;
        }
        &--active {
          button {
            background: $primary !important;
            color: $white;
          }
        }
      }
    }

    .viewsManagement {
      .viewsManagementHeaderWrapper {
        padding: 0 0.5rem;
        min-height: 2rem;
        .privateViewHeaderButtons {
          display: inline-block;
          float: right;
        }
      }
    }

    .helpCenterManagement {
      .helpCenterManagementToolbar {
        display: flex;
        justify-content: end;
      }
    }

    .helpCenter {
      .helpCenterTileLayoutItem {
        .k-tilelayout-item-header {
          padding: 0;
          border: 0;
        }
      }
      .helpCenterMenu {
        display: grid;
        row-gap: 0.875rem;
        .k-chip {
          height: 3rem;
          font-size: 0.875rem;
          font-weight: 400;
        }
        .helpCenterMenuItem {
          background: $lightSecondary;
          &Selected {
            background: $secondary;
          }
        }
      }
      .helpCeneterContent {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
      }
    }
  }

  .gearGridContainer {
    .gearGrid {
      .noRecordsRow {
        position: fixed;
        left: 50%;
        width: auto !important;
        height: auto !important;
        margin-left: -10em;
      }
      .columnHeader {
        .k-link {
          display: flex;
          align-items: center;
          .k-column-title {
            width: 100%;
          }
          .filterIcon {
            svg {
              color: rgba(0, 0, 0, 0.54);
              cursor: default;
              width: 1rem;
            }
          }
        }
      }
      border-radius: 1.25rem;
      .headerTitle {
        float: left;
      }
      .headerIcons {
        display: flex;
        justify-content: right;
        align-items: center;
      }

      .editableNumericTextBoxCell {
        text-align: right;
        input {
          text-align: right;
        }
      }
      .highlightedSearchPhrase {
        background: $primary;
        color: $white;
      }

      .gridIcon {
        svg {
          height: 0.875rem;
        }
      }

      .k-grid-table-wrap {
        width: 100% !important;
      }
    }
    .gearToolbarContainer {
      border-radius: 1rem;

      .gearToolbar {
        margin-top: -0.25rem;
        display: grid;
        width: 100%;
        grid-template-columns: 90px auto 290px;
        align-items: end;
        gap: 0.5rem;

        .actionButtonsContainer {
          width: 100%;
          display: flex;
          align-items: end;
          justify-content: space-between;
          gap: 0.5rem;
        }
        .globalFilter {
          display: flex;
          gap: 0.5rem;
          justify-content: center;
          .filterItem {
            flex: 1;
            .k-multiselect,
            .k-floating-label-container,
            input {
              width: 100%;
            }
            .dateTimeRangeFilter {
              display: flex;
              align-items: center;
              justify-content: space-between;
              gap: 0.5rem;
              .k-datetimepicker,
              .k-dateinput,
              .k-input {
                width: 100%;
              }
            }
          }
        }
        .chipsButton {
          border: 1px solid $inputBorderLightGrey !important;
          background: $white;
          color: $lightInteractionBlack;
          &:hover {
            background: $backgroundBaseLight;
          }
          &:focus {
            background: $white !important;
            border: 2px solid #2b8ede !important;
          }
          &:disabled {
            color: #a9a9a9;
            border-color: #a9a9a9;
          }
          &:active {
            background: $inputBorderLightGrey;
          }

          &--selected {
            color: $white;
            background: $lightInteractionBlack;
            &:hover {
              color: $white;
              background: $black;
            }
            &:focus {
              background: $lightInteractionBlack !important;
              border: 2px solid #2b8ede !important;
            }
          }
        }

        .chipsButtonLink {
          background: $white;
          color: $lightInteractionBlack;
          padding: 0.5rem;
          border-radius: 50%;
          &:hover {
            background: $backgroundBaseLight;
            svg {
              stroke: $primary;
              stroke-width: 1;
            }
          }
          &Disabled {
            opacity: 0.4;
            svg {
              cursor: default;
            }
            &:hover {
              background: none;
              svg {
                stroke: none;
                stroke-width: 0;
              }
            }
          }
        }

        .indexDropdown {
          width: 130px;
        }
      }
    }

    .centeredField {
      text-align: center;
      vertical-align: middle;
      input {
        margin: auto;
        display: block;
      }
    }

    .k-pager {
      border-radius: 1.25rem;
    }
  }

  .footer {
    z-index: 1000;
    margin: 0 (-$pagePadding);
    @media only screen and (max-width: 1600px) {
      margin: 0 (-$pagePaddingSmall);
    }
    font-weight: 400;
    font-size: 0.75rem;
    color: $lightBlack;
    position: fixed;
    bottom: 0;
    width: 100%;
    .footerGrid {
      padding: 0 2rem;
      height: $footerHeight;
      background-color: $backgroundBaseLight;
      box-shadow: 0 1px 0 $borderSubtleLight inset;
      font-family: $kendo-font-family;
    }
    a {
      font-size: 1rem;
      color: $lightBlack;
      text-decoration: none;
      &:hover {
        cursor: pointer;
      }
    }
  }

  .gearForm {
    .k-grid-layout {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      div:first-of-type {
        width: 100%;
        .k-textarea {
          width: 100%;
        }
      }
      .k-button-group {
        width: auto !important;
      }
      .k-editor-content {
        iframe {
          min-height: 400px;
        }
      }
    }
    .gearFieldWrapper {
      div {
        width: 100%;
      }
    }
    .gearFormButtons {
      display: flex;
      gap: 1rem;
      justify-content: flex-end;
      align-items: center;
      width: 100%;
      padding: 1rem;
      button {
        min-width: 100px;
      }
    }
  }
}

.gearLoader {
  text-align: center;
  div:last-child {
    box-shadow: none;
    .gearLoaderText {
      color: $primary;
    }
  }
  &--no-background {
    div:last-child {
      background-color: transparent !important;
    }
  }
}

.gearNotification {
  right: 0;
  bottom: 0;
  align-items: flex-start;
  flex-wrap: wrap-reverse;
}

.settingsPopupContent {
  margin-right: $pagePadding / 2;
}

.dropDownButtonIconRight {
  .k-icon {
    order: 1 !important;
  }
}
.filterActionButtonOption {
  width: 100%;
  display: flex;
  align-items: center;
  span {
    margin-right: 0.5rem;
  }
  * {
    cursor: pointer;
  }
  &:hover {
    * {
      color: $white;
    }
  }
  &Disabled {
    opacity: 0.4;
    * {
      cursor: default;
    }
    &:hover {
      * {
        color: none;
      }
    }
  }
}

.filterActionsDialog {
  .k-dialog-titlebar {
    background: $warning;
  }
  .k-dialog {
    max-width: 500px;
  }
  .k-actions {
    justify-content: center;
    .k-button {
      max-width: 200px;
    }
  }
}

.previewMaintanancePage {
  height: 100%;
  padding: 0 $pagePadding;
  overflow-y: auto;
  @media only screen and (max-width: 1600px) {
    padding: 0 $pagePaddingSmall;
  }
}

.previewRerportIssue {
  height: 100%;
  padding: 0 $pagePadding;
  margin-top: 2rem;
  overflow-y: auto;
  @media only screen and (max-width: 1600px) {
    padding: 0 $pagePaddingSmall;
  }
}

.dateTimeCalendar {
  .dateTimeFieldPicker {
    outline: 0;
    padding: 0 1rem;
    text-align: left;
    list-style-type: none;
  }
}

.gearIcon {
  cursor: pointer;

  &Small {
    width: 1rem;
  }
  &Medium {
    width: 1.5rem;
  }
  &Large {
    width: 2rem;
  }
}
