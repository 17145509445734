.box {
  padding: 4px;
}

.k-flex-container {
  display: flex;
}
.k-flex-align-content-space-between {
  align-content: space-between;
}
.k-flex-10 {
  flex: 1 1 10%;
}
.k-flex-20 {
  flex: 1 1 20%;
}
.k-flex-30 {
  flex: 1 1 30%;
}
.k-flex-40 {
  flex: 1 1 40%;
}
.k-flex-50 {
  flex: 1 1 50%;
}
.k-flex-60 {
  flex: 1 1 60%;
}
.k-flex-70 {
  flex: 1 1 70%;
}
.k-flex-80 {
  flex: 1 1 80%;
}
.k-flex-90 {
  flex: 1 1 90%;
}
.k-flex-100 {
  flex: 1 1 100%;
}
